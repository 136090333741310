import React from 'react'
import FetchModel from './FetchModel'
import AuthStore from './AuthStore'
import UserStore from './UserStore'
import InitialDataStore from './InitialDataStore'
import BalancesStore from './BalancesStore'
import TransactionStore from './TransactionStore'
import AddressesStore from './AddressesStore'
import FeesStore from './CommissionsStore/CommissionsStore'
import MerchantStore from './MerchantStore'
import AlertsStore from './AlertsStore'
import ExchangeStore from './ExchangeStore'
import ReportsStore from './ReportsStore'
import { configure } from 'mobx'
import ApiKeysStore from './ApiKeysStore'
import UtilityStore from './UtilityStore'
import WithdrawAddressesStore from './WithdrawAddressesStore'
import FiatStore from './FiatStore'
import RedirectPaymentsStore from './RedirectPaymentsStore'

configure({
  // enforceActions: 'always',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // disableErrorBoundaries: true
})

class Store {
  public api: FetchModel
  public authStore: AuthStore
  public userStore: UserStore
  public initialStore: InitialDataStore
  public balancesStore: BalancesStore
  public transactionStore: TransactionStore
  public addressesStore: AddressesStore
  public withdrawAddressesStore: WithdrawAddressesStore
  public feesStore: FeesStore
  public merchantStore: MerchantStore
  public alertsStore: AlertsStore
  public exchangeStore: ExchangeStore
  public reportsStore: ReportsStore
  public apiKeysStore: ApiKeysStore
  public utilityStore: UtilityStore
  public fiatStore: FiatStore
  public redirectPaymentsStore: RedirectPaymentsStore

  constructor() {
    this.api = new FetchModel(this)
    this.authStore = new AuthStore(this)
    this.initialStore = new InitialDataStore(this)
    this.merchantStore = new MerchantStore(this)
    this.userStore = new UserStore(this)
    this.balancesStore = new BalancesStore(this)
    this.transactionStore = new TransactionStore(this)
    this.addressesStore = new AddressesStore(this)
    this.withdrawAddressesStore = new WithdrawAddressesStore(this)
    this.feesStore = new FeesStore(this)
    this.alertsStore = new AlertsStore(this)
    this.exchangeStore = new ExchangeStore(this)
    this.reportsStore = new ReportsStore(this)
    this.apiKeysStore = new ApiKeysStore(this)
    this.utilityStore = new UtilityStore(this)
    this.fiatStore = new FiatStore(this)
    this.redirectPaymentsStore = new RedirectPaymentsStore(this)
    void this.userStore.getUserInfo()
  }

  resetStores(): void {
    this.api = new FetchModel(this)
    this.authStore = new AuthStore(this)
    this.initialStore = new InitialDataStore(this)
    this.merchantStore = new MerchantStore(this)
    this.userStore = new UserStore(this)
    this.balancesStore = new BalancesStore(this)
    this.transactionStore = new TransactionStore(this)
    this.addressesStore = new AddressesStore(this)
    this.withdrawAddressesStore = new WithdrawAddressesStore(this)
    this.feesStore = new FeesStore(this)
    this.exchangeStore = new ExchangeStore(this)
    this.reportsStore = new ReportsStore(this)
    this.apiKeysStore = new ApiKeysStore(this)
    this.utilityStore = new UtilityStore(this)
  }
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const StoreContext = React.createContext<Store>({} as Store)

export type TStoreProvider = {
  children: React.ReactNode
  store: Store
}

export const StoreProvider = ({
  children,
  store
}: TStoreProvider): JSX.Element => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

/* Hook to use store in any functional component */
export const useStore = (): Store => React.useContext(StoreContext)

export default Store
