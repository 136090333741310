import Logo from '../../assets/images/svg/logo_horizontal.svg'
import MainMenu from '../../ui/Menu'
import colors from '../../assets/scss/themes/colors.module.scss'
import { Dropdown } from 'react-bootstrap'
import ProfileIcon from '../../assets/images/svg/userprofile.svg'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import ArrowIcon from 'src/assets/images/svg/ArrowIcon'
import BalancesIcon from '../../assets/images/svg/navigation-balances.svg'
import TransactionsIcon from '../../assets/images/svg/navigation-transactions.svg'
import ReportsIcon from '../../assets/images/svg/navigation-reports.svg'
import AddressesIcon from '../../assets/images/svg/navigation-addresses.svg'
import FeesIcon from '../../assets/images/svg/navigation-fees.svg'
import CitronLogo from 'src/assets/images/CitronLogoYellow.svg'
import MerchantsDropdown from '../MerchantsDropdown/MerchantsDropdown'
import styles from './Header.module.scss'
const menu = [
  {
    title: 'Balances',
    link: 'balances',
    end: false,
    icon: <BalancesIcon />
  },
  {
    title: 'Transactions',
    link: 'transactions',
    end: false,
    icon: <TransactionsIcon />
  },
  {
    title: 'Reports',
    link: 'reports',
    end: false,
    icon: <ReportsIcon />
  },
  {
    title: 'Addresses & Bank Accounts',
    link: 'addresses',
    end: false,
    icon: <AddressesIcon />
  },
  {
    title: 'Fees',
    link: 'fees',
    end: false,
    icon: <FeesIcon />
  }
  // {
  //   title: 'Team',
  //   link: 'team'
  // }
]
const Header = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const location = useLocation()

  const isCurrentlyOnProfilePage = location.pathname.includes('/profile')

  return (
    <div className={styles.Header + ' ' + 'header'}>
      <div className={styles.LogoWithMerchants}>
        <div>
          <CitronLogo />
        </div>
        <MerchantsDropdown />
      </div>
      <MainMenu items={menu} />
      <div className="header-navbar __mobile">
        {menu.map((menuItem) => {
          return (
            <div className="header-navbar-item" key={menuItem.title}>
              <NavLink to={menuItem.link}>
                {menuItem.icon}
                <div>{menuItem.title}</div>
              </NavLink>
            </div>
          )
        })}
      </div>
      <div
        className={'left header-column align-items-center'}
        style={{ marginLeft: 'auto' }}
      >
        <p
          style={{ marginBottom: 0, marginLeft: 10 }}
          className="header-userEmail"
        >
          {store.userStore.user?.email}
        </p>
        <Dropdown>
          <Dropdown.Toggle className={'icon-dd'} size={'sm'}>
            <ProfileIcon
              className={`profile-icon${
                isCurrentlyOnProfilePage ? '--active' : ''
              }`}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu variant={'dark'}>
            <Dropdown.Item as={Link} to="/profile">
              Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/profile/security">
              Security
            </Dropdown.Item>
            <Dropdown.Item href="https://docs.citrn.io/">
              Documentation
            </Dropdown.Item>
            <Dropdown.Item href="mailto:support@citrn.io">
              Support
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                void store.userStore.logout()
              }}
              style={{
                height: '45px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colors.main,
                color: colors.bg
              }}
            >
              <div>
                <ArrowIcon fill={colors.bg} />
                Logout
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
})

export default Header
