import type React from 'react'
import type PaginationStore from '../../models/PaginationStore'
import ReactPaginate from 'react-paginate'
import { observer } from 'mobx-react'
import { Dropdown, Stack } from 'react-bootstrap'
import './pagination.styles.scss'

export type TPaginationProps = {
  pagination: PaginationStore
}

const PaginationComponent: React.FC<TPaginationProps> = observer(
  ({ pagination }) => {
    return (
      <div className="Pagination">
        <Stack direction={'horizontal'} gap={3}>
          <ReactPaginate
            onPageChange={(event) => {
              // React paginate counts pages from 0, but BE starts with 1
              const pageToChange = event.selected + 1
              pagination.setPage(pageToChange)
            }}
            pageRangeDisplayed={5}
            pageCount={pagination.pagesLength}
            forcePage={pagination.currentPage - 1}
            renderOnZeroPageCount={null}
            breakLabel="..."
            nextLabel="Next"
            previousLabel="Prev"
            containerClassName={'pagination'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
          />
        </Stack>
        <div className="items-per-page">
          <p style={{ margin: 0 }}>
            <small>{'Items per page'}</small>
          </p>
          <Dropdown>
            <Dropdown.Toggle
              variant="dark"
              id="dropdown-basic"
              size={'sm'}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {pagination.itemsPerPage}
            </Dropdown.Toggle>
            <Dropdown.Menu variant="dark">
              <Dropdown.Item
                onClick={() => {
                  pagination.init(10, pagination.total)
                }}
              >
                10
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  pagination.init(25, pagination.total)
                }}
              >
                25
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  pagination.init(50, pagination.total)
                }}
              >
                50
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    )
  }
)

export default PaginationComponent
