import { observer } from 'mobx-react'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { type TableColumn } from 'react-data-table-component'
import { type TAddressesListItem } from 'src/models/AddressesStore'
import { useStore } from 'src/models/Store'
import Search from 'src/ui/Filters/Search'
import { debounce } from 'debounce'
import Button, { EButtonType } from 'src/ui/Button'
import ClearIcon from '../../../assets/images/svg/clear.svg'

const columns: Array<TableColumn<TAddressesListItem>> = [
  {
    name: 'ID',
    selector: (row) => row.id,
    allowOverflow: false,
    center: true,
    maxWidth: '200px',
    grow: 0,
    style: {
      backgroundColor: '#2E2E2E',
      borderRadius: 10,
      height: 25,
      alignSelf: 'center'
    },
    id: 1
  },
  {
    name: 'Network',
    cell: (row) => displayNetworkWithImage(row.network),
    id: 2
  },
  { name: 'Address', selector: (row) => row.address, id: 3 }
]

const displayNetworkWithImage = (networkName: string): JSX.Element => {
  return (
    <div>
      <img
        src={`/assets/images/networks/${networkName}.svg`}
        style={{ marginRight: 10 }}
      />
      {networkName}
    </div>
  )
}

const SystemAddressesPage = observer(() => {
  const { addressesStore } = useStore()

  return (
    <>
      <div className="AddressesPage">
        <div className="AddressesPage-Actions">
          <Search
            title="Address"
            value={addressesStore.filters.searchString.slug ?? ''}
            onChange={(e) =>
              debounce(
                addressesStore.filters.setSearchFields([
                  {
                    field: 'slug',
                    value: e.target.value
                  }
                ]),
                300
              )
            }
          />

          {Object.keys(addressesStore.filters.searchString).length > 0 ? (
            <Button
              icon={<ClearIcon style={{ marginRight: 3 }} />}
              title={'Clear'}
              onClick={() => {
                addressesStore.filters.resetFilters()
              }}
              buttonType={EButtonType.LINK}
            />
          ) : null}
        </div>
        <BaseDataTable
          columns={columns}
          data={addressesStore.list}
          paginationLogic={addressesStore.pagination}
        />
      </div>
    </>
  )
})

export default SystemAddressesPage
