import { observer } from 'mobx-react'
import BaseDataTable from 'src/components/BaseDataTable/BaseDataTable'
import { transactionListColumns } from './TransactionsList.columns'
import { useStore } from 'src/models/Store'
import TransactionFilters from '../TransactionFilters'
import TransactionsSubFilter from '../TransactionsSubFilters'
import './TransactionsList.scss'
import type { TTransactionListItem } from 'src/models/TransactionStore'
import { Outlet, useNavigate } from 'react-router-dom'

const TransactionsList = observer(() => {
  const { transactionStore } = useStore()
  const navigate = useNavigate()

  const handleNavigate = (item: TTransactionListItem) => {
    navigate(`info/${item.id}`)
  }

  return (
    <div className="TransactionsList">
      <TransactionFilters />
      <TransactionsSubFilter />
      <BaseDataTable
        columns={transactionListColumns}
        data={transactionStore.list}
        paginationLogic={transactionStore.pagination}
        highlightOnHover
        pointerOnHover
        onRowClicked={handleNavigate}
      />
      <Outlet />
    </div>
  )
})
export default TransactionsList
