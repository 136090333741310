import moment from 'moment'
import type { TableColumn } from 'react-data-table-component'
import type { TTransactionListItem } from 'src/models/TransactionStore'

export const transactionListColumns: Array<TableColumn<TTransactionListItem>> =
  [
    {
      name: 'ID',
      selector: (row) => row.id,
      style: {
        '>div': {
          borderRadius: '100px',
          backgroundColor: 'var(--color-primary800)',
          width: '100%',
          padding: '5px',
          textAlign: 'center'
        }
      },
      center: true
    },
    {
      name: 'Created',
      selector: (row) => moment(row.created).format('DD.MM.YYYY \n hh:mm:ss'),
      wrap: true
    },
    { name: 'Amount', selector: (row) => row.amount },
    { name: 'Network fee', selector: (row) => row.network_fee ?? '-' },
    { name: 'Type', selector: (row) => row.type },
    {
      name: 'Status',
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <span className={`status status-${row.status}`} />
          {row.status}
        </div>
      )
    },
    { name: 'Real amount', selector: (row) => row.real_amount },
    {
      name: 'Amount with commission',
      selector: (row) => row.amount_with_commission
    },
    {
      name: 'Network',
      selector: (row) => row.network,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 5,
            textTransform: 'capitalize'
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `/assets/images/ps/${row.coin.toLowerCase()}.svg`
            }
            alt={`${row.coin.toLowerCase()} image`}
          />{' '}
          <span>{row.network}</span>
        </div>
      ),
      grow: 2
    },
    { name: 'Coin', selector: (row) => row.coin },
    { name: 'Merchant', selector: (row) => row.merchant ?? '-' },
    { name: 'Wallet', selector: (row) => row.wallet ?? '-' },
    {
      name: 'Foreign ID',
      selector: (row) => row.foreign_id ?? '-',
      style: {
        '>div': {
          borderRadius: '100px',
          backgroundColor: 'var(--color-primary800)',
          width: '100%',
          padding: '5px',
          textAlign: 'center'
        }
      },
      center: true
    }
  ]
