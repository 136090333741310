import { forwardRef, type PropsWithChildren } from 'react'
import styles from './TextComponent.module.scss'
import classNames from 'classnames'

type TextComponentProps = {
  bold?: boolean
  body2?: boolean
  body4?: boolean
} & PropsWithChildren

/**
 * Body 1 by default
 */
const TextComponent = forwardRef<HTMLParagraphElement, TextComponentProps>(
  ({ body2, body4, bold, children }, ref) => {
    const textComponentClassnames = classNames(styles.TextComponent, {
      [styles.Bold]: bold,
      [styles.Body4]: body4,
      [styles.Body2]: body2
    })
    return (
      <p className={textComponentClassnames} ref={ref}>
        {children}
      </p>
    )
  }
)

TextComponent.displayName = 'TextComponent'
export default TextComponent
