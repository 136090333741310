import { observer } from 'mobx-react'
import { Table } from 'react-bootstrap'
import Button, { EButtonType } from '../../ui/Button'
import Payin from '../../assets/images/svg/Payin.svg'
import colors from 'src/assets/scss/themes/colors.module.scss'
import Payout from '../../assets/images/svg/payout.svg'
import { Outlet, useNavigate } from 'react-router-dom'
import styles from './balancePage.module.scss'
import { useStore } from '../../models/Store'
import useSideBarModal from 'src/hooks/useSideBarModal'
import ExchangeIcon from 'src/assets/images/svg/ExchangeIcon'
import ExchangeModal from 'src/components/Modals/ExchangeModal/ExchangeModal'

const Balance = observer(() => {
  const { balancesStore, addressesStore } = useStore()
  const navigate = useNavigate()
  const { openModal } = useSideBarModal()
  return (
    <>
      <div className={styles.balance}>
        <Table responsive>
          <thead>
            <tr>
              <th>Balances</th>
              <th colSpan={3}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {/*  */}
            {balancesStore.items.slice().map((item) => {
              const exchangeOperationAvailability =
                item.operations_availability.find(
                  (op) => op.type === 'exchange_withdraw'
                )

              const topUpOperationAvailability =
                item.operations_availability.find(
                  (op) => op.type === 'top_up_deposit'
                )

              const withdrawOperationAvailability =
                item.operations_availability.find(
                  (op) => op.type === 'settlement_withdraw'
                )
              const isAvailableForExchange =
                exchangeOperationAvailability?.available ?? false

              const isTopUpAvailable =
                item.operations_availability.find(
                  (op) => op.type === 'top_up_deposit'
                )?.available ?? false

              const isWithdrawAvailable =
                withdrawOperationAvailability?.available ?? false

              const imageName = item.currency.toLowerCase()
              return (
                <tr key={item.id}>
                  <td>
                    <div className={'balance-info'}>
                      <div className={'balance-info-icon'}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            `/assets/images/ps/${imageName}.svg`
                          }
                          alt={imageName}
                        />
                      </div>
                      <div className={'balance-info-data'}>
                        <p>{item.currency}</p>
                        <p className={'color-main'}>
                          {item.balance} {item.currency}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td width={150}>
                    <Button
                      classes={styles.actionButton}
                      disabled={!isAvailableForExchange}
                      icon={<ExchangeIcon stroke={colors.main} />}
                      title={'Exchange'}
                      onClick={() => {
                        openModal(
                          <ExchangeModal
                            chosenCoin={{
                              ...item,
                              label: item.currency,
                              value: item
                            }}
                            networkId={
                              exchangeOperationAvailability?.network_id
                            }
                          />,
                          <ExchangeIcon stroke={colors.main} />,
                          'Exchange'
                        )
                      }}
                      buttonType={EButtonType.LINK}
                    />
                  </td>
                  <td width={150}>
                    <Button
                      classes={styles.actionButton}
                      disabled={!isTopUpAvailable}
                      icon={<Payin fill={colors.main} stroke={colors.main} />}
                      title={'Top up'}
                      onClick={() => {
                        if (item.is_fiat) {
                          navigate(`topup-fiat/${item.currency}`)
                          return
                        }
                        navigate(`topup/${item.id}`)
                      }}
                      buttonType={EButtonType.LINK}
                    />
                  </td>
                  <td width={150}>
                    <Button
                      classes={styles.actionButton}
                      disabled={!isWithdrawAvailable}
                      icon={<Payout fill={colors.main} stroke={colors.main} />}
                      title={'Withdraw'}
                      onClick={() => {
                        if (item.is_fiat) {
                          navigate(`withdraw-fiat/${item.currency}`, {
                            state: {
                              currency: item.currency
                            }
                          })
                          return
                        }
                        addressesStore.setSelectedCoinForWithdraw(item)
                        navigate(`withdraw/${item.id}`)
                      }}
                      buttonType={EButtonType.LINK}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      <Outlet />
    </>
  )
})

export default Balance
