/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type Store from './Store'
import { autorun, makeAutoObservable, runInAction, toJS } from 'mobx'
import FiltersStore from './FiltersStore'
import PaginationStore from './PaginationStore'
import { type BasePaginationRequest } from './FetchModel'

export type TTransactionsGetParams = {
  page: number
  page_size: number
  date_from?: Date
  date_to?: Date
  foreign_id?: string
  type?: string
  status?: string
  network?: string
  coin?: string
}

export type TTransactionListItem = {
  id: number
  created: string
  amount: string
  network_fee: string | null
  type: string
  status: string
  real_amount: string
  amount_with_commission: string
  txid: string
  txid_url: string
  network: string
  coin: string
  merchant: string
  wallet: string
  foreign_id: string
  commission_amount: number
}

export type TransactionExchangeCalculation = {
  from_coin_name: string
  from_currency: string
  to_currency: string
  request_currency: string
  to_exchange_currency: string
  original_amount: number
  exchanged_amount: number
  exchange_rate: number
}

export const transactionConfigPageFilters = [
  'page',
  'page_size',
  'magic_search',
  'transaction_id',
  'network',
  'coin',
  'status',
  'date_start',
  'date_end',
  'risk_decision',
  'merchants_id_lst',
  'transaction_type',
  'foreign_id'
] as const

class TransactionStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<TransactionStore, 'rootStore'>(this, {
      rootStore: false
    })

    this.filters = new FiltersStore(rootStore, [
      ...transactionConfigPageFilters
    ])

    this.pagination = new PaginationStore()
  }

  filters: FiltersStore<typeof transactionConfigPageFilters>
  pagination: PaginationStore
  list: TTransactionListItem[] = []
  currentLimit: number = 10
  currentOffset: number = 0
  total: number = 0
  currentTransaction: {
    transaction: TTransactionListItem
    exchange_calculation: TransactionExchangeCalculation | null
  } = {
    transaction: {} as TTransactionListItem,
    exchange_calculation: null
  }

  currentTransactionError: boolean = false
  currentTransactionLoading: boolean = false

  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false
  listener = (): void => {}

  subscribe(): void {
    this.listener = autorun(() => {
      if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
        void this.getTransactions({
          page: this.pagination.currentPage,
          page_size: this.pagination.itemsPerPage,
          ...toJS(this.filters.searchString)
        })
      }
    })
  }

  unsubscribe(): void {
    this.listener()
    this.filters.resetFilters()
  }

  async getTransactionInfo(id: number): Promise<void> {
    this.currentTransactionLoading = true
    const response = await this.rootStore.api.get<{
      transaction: TTransactionListItem
      // logs: [unknown]
      exchange_calculation: TransactionExchangeCalculation
    }>(`/jsapi/transactions/${id}`)

    if (response !== undefined) {
      runInAction(() => {
        this.currentTransactionError = false
        this.currentTransaction = response
        this.currentTransactionLoading = false
      })
    }
  }

  async getTransactions(params: TTransactionsGetParams): Promise<void> {
    const search = { ...params }
    this.isLoading = true
    const response = await this.rootStore.api.get<
      {
        items: TTransactionListItem[]
      } & BasePaginationRequest
    >('/jsapi/transactions', {
      ...search
    })

    if (response !== undefined) {
      runInAction(() => {
        this.list = response.items
        this.total = response.total
        this.pagination.init(this.pagination.itemsPerPage, response.total)
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }

  downloadTransactionPDF(id: TTransactionListItem['id']): void {
    window.open(`/jsapi/transactions/${id}/receipt`)
  }
}

export default TransactionStore
