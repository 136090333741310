import type Store from './Store'
import { autorun, makeAutoObservable, observable, toJS, when } from 'mobx'
import FiltersStore from './FiltersStore'
import PaginationStore from './PaginationStore'
import { EAlertTypes } from './AlertsStore'
import { type BalanceItem } from './BalancesStore'
import { type BasePaginationRequest } from './FetchModel'

export type TWithdrawGetParams = {
  page: number
  page_size?: number
  network_id?: string
  currency?: string
  name?: string
  address?: string
}

export type TAddressesListItem = {
  id: number
  address: string
  network: string
  foreign_id: string
  is_locked: boolean
}

export type TWithdrawAddress = {
  id: number
  slug: string
  network_id: number
  name: string
  network_name: string
}

export type WithdrawAddressesWithPagination = {
  items: TWithdrawAddress[]
} & BasePaginationRequest

export type TAddAddressesParams = {
  tag: string
  slug: string
  network_id: number
  name: string
  tfa_code: string
}

export type TDeleteAddressParams = {
  id: number
  tfa_code: number | string
}

export const withdrawAddressesFilterConfig = [
  'page',
  'page_size',
  'network_id',
  'currency',
  'name',
  'address'
] as const
// INFO: Get withdraw endpoint yields different results based on if pagination is added or not

class WithdrawAddressesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<WithdrawAddressesStore, 'rootStore'>(this, {
      rootStore: false,
      withdrawAddresses: observable
    })

    this.filters = new FiltersStore(rootStore, [
      ...withdrawAddressesFilterConfig
    ])
    this.pagination = new PaginationStore()
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getWithdrawAddressesPagination({
              page_size: 10,
              page: this.pagination.currentPage,
              ...this.filters.searchString
            })
          }
        })
      }
    )
  }

  filters: FiltersStore<typeof withdrawAddressesFilterConfig>
  pagination: PaginationStore
  total: number = 0
  isLoading: boolean = false
  loadingError: boolean = false

  withdrawAddresses: TWithdrawAddress[] = []
  withdrawAddressesPagination: WithdrawAddressesWithPagination = {
    page: 1,
    page_size: 10,
    total: 0,
    items: []
  }

  withdrawAddressesStatus: boolean = false

  selectedCoinForWithdraw: BalanceItem | undefined

  setWithdrawAddresses(addresses: TWithdrawAddress[]): void {
    this.withdrawAddresses = addresses.map((address) => {
      /**
       * Adds a network_name to withdraw addresses for adding network info next to addresses,
       * if not found just return an empty string
       */
      const network_name = this.rootStore.initialStore.networks.items.find(
        (item) => item.id === address.network_id
      )

      if (network_name === undefined) {
        return { ...address, network_name: '' }
      }
      return { ...address, network_name: network_name.name }
    })
    this.withdrawAddressesStatus = true
    this.isLoading = false
  }

  // async getAddresses(params: TTransactionsGetParams): Promise<void> {
  //   const search = { ...params, ...toJS(this.filters.searchString) }
  //   this.isLoading = true
  //   const response = await this.rootStore.api.get('/jsapi/addresses', {
  //     ...search
  //   })
  //   runInAction(() => {
  //     this.list = response.items
  //     this.pagination.init(response.page_size, response.total)
  //     this.isFetched = true
  //     this.isLoading = false
  //     this.loadingError = false
  //   })
  // }

  async getWithdrawAddresses(params: {
    network_id?: number
    page?: number
    page_size?: number
    address?: number
  }): Promise<TWithdrawAddress[] | undefined> {
    this.withdrawAddressesStatus = false
    this.isLoading = true
    const search = { ...params, ...toJS(this.filters.searchString) }
    const response = await this.rootStore.api.get<TWithdrawAddress[]>(
      '/jsapi/withdraw-addresses',
      { ...search }
    )
    if (response !== undefined) {
      this.setWithdrawAddresses(response)
    }
    return response
  }

  async getWithdrawAddressesPagination(
    params: TWithdrawGetParams
  ): Promise<void> {
    this.withdrawAddressesStatus = false
    const search = { ...params, ...toJS(this.filters.searchString) }
    const response =
      await this.rootStore.api.get<WithdrawAddressesWithPagination>(
        '/jsapi/withdraw-addresses',
        { ...search }
      )
    if (response !== undefined) {
      this.pagination.init(response.page_size, response.total)
      this.setWithdrawAddressesPagination(response.items)
    }
  }

  setWithdrawAddressesPagination(addresses: TWithdrawAddress[]): void {
    this.withdrawAddressesPagination.items = addresses.map((address) => {
      /**
       * Adds a network_name to withdraw addresses for adding network info next to addresses,
       * if not found just return an empty string
       */
      const network_name = this.rootStore.initialStore.networks.items.find(
        (item) => item.id === address.network_id
      )

      if (network_name === undefined) {
        return { ...address, network_name: '' }
      }
      return { ...address, network_name: network_name.name }
    })
    this.withdrawAddressesStatus = true
    this.isLoading = false
  }

  async addWithdrawAddress(
    params: TAddAddressesParams
  ): Promise<TWithdrawAddress | undefined> {
    this.withdrawAddressesStatus = false
    const response = await this.rootStore.api.post<TWithdrawAddress>(
      '/jsapi/withdraw-addresses',
      params
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'Withdraw-address-add-success',
        title: 'Addressess',
        content: 'Address successfully added!',
        type: EAlertTypes.SUCCESS,
        timeout: 3000
      })
      await this.getWithdrawAddressesPagination({
        page_size: 10,
        page: this.pagination.currentPage,
        ...this.filters.searchString
      })
    }
    return response
  }

  async deleteAddress(params: TDeleteAddressParams): Promise<any> {
    const response = await this.rootStore.api.delete(
      `/jsapi/withdraw-addresses/${params.id}`,
      { tfa_code: params.tfa_code }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'withdraw-delete',
        title: 'Manage address',
        content: 'Address deleted successfully ',
        type: EAlertTypes.SUCCESS,
        timeout: 2000
      })
      // TODO: Strange bug, methods returns undefined, it was not like that before, look into
      return true
    }
  }

  async editWithdrawAddress(params: {
    name: string
    tfa_code: string
    addressId: string
  }): Promise<any> {
    const response = await this.rootStore.api.update(
      `/jsapi/withdraw-addresses/${params.addressId}`,
      { name: params.name, tfa_code: params.tfa_code }
    )
    if (response !== undefined) {
      this.rootStore.alertsStore.addAlert({
        id: 'withdraw-edit',
        title: 'Manage address',
        content: 'Address edited successfully ',
        type: EAlertTypes.SUCCESS,
        timeout: 2000
      })
    }
    return response
  }
}

export default WithdrawAddressesStore
