import Button, { EButtonType } from '../../ui/Button'
import './LoginPage.scss'
import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { type SubmitHandler, useForm } from 'react-hook-form'
import Input from 'src/ui/Input/Input'
import { OTPInput } from 'src/ui/Input/OtpInput'
import { useNavigate } from 'react-router-dom'
import useFormStepper from 'src/utils/hooks/useFormStepper'
import TextComponent from 'src/ui/TextComponent/TextComponent'

type LoginFormInput = {
  login: string // login could be an email or a simple username
  password: string
  tfa_code: string
  tempSessionKey: string
}
const LoginPage = observer(() => {
  const { userStore } = useStore()
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
    reset
  } = useForm<LoginFormInput>({
    defaultValues: { login: '', password: '', tfa_code: '' }
  })

  const navigate = useNavigate()
  const onSubmit: SubmitHandler<LoginFormInput> = async ({
    login,
    password,
    tfa_code
  }) => {
    const response = await userStore.login({ login, password })
    // Check if the user is not on 2FA step
    if (
      response !== undefined &&
      'temp_session_key' in response &&
      currentStepIndex !== 1
    ) {
      setValue('tempSessionKey', response.temp_session_key)
      moveToNextStep()
    }
  }

  const on2FASubmit: SubmitHandler<LoginFormInput> = async ({
    tfa_code,
    tempSessionKey
  }) => {
    await userStore.TFALogin({ tfa_code, tempSessionKey })
  }

  const { currentStepElement, moveToNextStep, moveStepBack, currentStepIndex } =
    useFormStepper([
      <div className="LoginPage" key={1}>
        <form>
          <h4 className={'text-center mb-4'}>Sign in to your account</h4>
          <Input
            label="Email"
            {...register('login', {
              required: { value: true, message: 'Field required' },
              minLength: { value: 2, message: 'Email too short' },
              maxLength: 50
            })}
            errors={errors.login}
          />
          <Input
            label="Password"
            type="password"
            {...register('password', {
              required: { value: true, message: 'Field required' },
              minLength: { value: 2, message: 'Password too short' },
              maxLength: 50
            })}
            errors={errors.password}
          />
          <Button
            title="Sign In"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          />
          <Button
            classes="LoginPage-ForgotPassword"
            buttonType={EButtonType.LINK}
            title="Forgot Your Password?"
            type="button"
            onClick={() => {
              navigate('/reset-password')
            }}
          />
        </form>
      </div>,

      <div className="LoginPage-OTPInput" key={2}>
        <h3 className={'text-center mb-2'}>Two-factor authentication</h3>
        <form>
          <OTPInput
            control={control}
            label="Please enter the passcode from your authenticator app"
            name="tfa_code"
            onFilled={handleSubmit(on2FASubmit)}
          />
          {/* <TextComponent>
            If you have any issues, contact support@email.com
          </TextComponent> */}
        </form>
      </div>
    ])

  return currentStepElement
})

export default LoginPage
