import type Store from '../Store'
import {
  action,
  autorun,
  makeAutoObservable,
  runInAction,
  toJS,
  when
} from 'mobx'
import FiltersStore from '../FiltersStore'

export type TTransactionsGetParams = {
  commission_type: 'b2b' | 'c2b'
}

type TFeeCommisions = {
  percent_value: string
  min_amount: string
  absolute_amount: string
  min_amount_currency: string
  network_id: number
}

type Fees = Record<string, Record<string, TFeeCommisions[]>>

class FeesStore {
  constructor(private readonly rootStore: Store) {
    this.rootStore = rootStore
    makeAutoObservable<FeesStore, 'rootStore'>(this, {
      rootStore: false,
      getFees: action
    })
    this.filters = new FiltersStore(rootStore, ['commission_type'])
    when(
      () =>
        this.rootStore.authStore.isAuth &&
        !Number.isNaN(this.rootStore.merchantStore.currentMerchant.id),
      () => {
        autorun(() => {
          if (!Number.isNaN(this.rootStore.merchantStore.currentMerchant.id)) {
            void this.getFees({ commission_type: 'b2b' })
            void this.getFees({ commission_type: 'c2b' })
          }
        })
      }
    )
  }

  filters: FiltersStore<['commission_type']>

  isLoading: boolean = false
  isFetched: boolean = false
  loadingError: boolean = false
  b2b: Fees = {}
  c2b: Fees = {}

  async getFees(params: TTransactionsGetParams): Promise<void> {
    const search = { ...params, ...toJS(this.filters.searchString) }
    this.isLoading = true
    const response = await this.rootStore.api.get<Fees>('/jsapi/commissions', {
      ...search
    })

    if (response !== undefined) {
      runInAction(() => {
        if (search.commission_type === 'b2b') {
          this.b2b = response
        } else {
          this.c2b = response
        }
        this.isFetched = true
        this.isLoading = false
        this.loadingError = false
      })
    }
  }
}

export default FeesStore
