import { observer } from 'mobx-react'
import { useStore } from '../../models/Store'
import { useEffect } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import FeesFilters from './FeesFilters'
import { Outlet } from 'react-router-dom'
import Preloader from '../../ui/Preloader'

const FeesPage = observer(() => {
  const { feesStore } = useStore()
  if (feesStore.isLoading && !feesStore.isFetched) return <Preloader />
  return (
    <>
      <FeesFilters />
      <Outlet />
    </>
  )
})

const B2b = observer(() => {
  const { feesStore } = useStore()
  useEffect(() => {
    feesStore.filters.setSearchFields([
      { field: 'commission_type', value: 'b2b' }
    ])
  }, [])

  return (
    <>
      <Table className={'citron-colors'} responsive>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Top up Fee</th>
            <th>Min Amount Fee</th>
            <th>Exchange Fee</th>
            <th>Min Amount Fee</th>
            <th>Settlement Fee</th>
            <th>Min Amount Fee</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(feesStore.b2b).map((key) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>{feesStore.b2b[key].top_up_deposit[0].percent_value}%</td>
                <td>
                  {feesStore.b2b[key].top_up_deposit[0].min_amount}{' '}
                  {feesStore.b2b[key].exchange_withdraw[0].min_amount_currency}
                </td>
                <td>
                  {feesStore.b2b[key].exchange_withdraw[0].percent_value}%
                </td>
                <td>
                  {feesStore.b2b[key].exchange_withdraw[0].min_amount}{' '}
                  {feesStore.b2b[key].exchange_withdraw[0].min_amount_currency}
                </td>
                <td>
                  {feesStore.b2b[key].settlement_withdraw[0].percent_value}%
                </td>
                <td>
                  {feesStore.b2b[key].settlement_withdraw[0].min_amount}{' '}
                  {
                    feesStore.b2b[key].settlement_withdraw[0]
                      .min_amount_currency
                  }
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
})
const C2b = observer(() => {
  const { feesStore } = useStore()
  useEffect(() => {
    feesStore.filters.setSearchFields([
      { field: 'commission_type', value: 'c2b' }
    ])
  }, [])

  return (
    <>
      <Table className={'citron-colors'} responsive>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Deposit with exchange</th>
            <th>Min Amount Fee</th>
            <th>Deposit Fee</th>
            <th>Min Amount Fee</th>
            <th>Payout with exchange</th>
            <th>Min Amount Fee</th>
            <th>Payout Fee</th>
            <th>Min Amount Fee</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(feesStore.c2b).map((key) => {
            return (
              <tr key={key}>
                <td>{key}</td>
                <td>
                  {
                    feesStore.c2b[key].customer_deposit_with_exchange[0]
                      .percent_value
                  }
                  %
                </td>
                <td>
                  {
                    feesStore.c2b[key].customer_deposit_with_exchange[0]
                      .min_amount
                  }{' '}
                  {feesStore.c2b[key].customer_deposit[0].min_amount_currency}
                </td>
                <td>{feesStore.c2b[key].customer_deposit[0].percent_value}%</td>
                <td>
                  {feesStore.c2b[key].customer_deposit[0].min_amount}{' '}
                  {feesStore.c2b[key].customer_deposit[0].min_amount_currency}
                </td>
                <td>
                  {
                    feesStore.c2b[key].customer_withdraw_with_exchange[0]
                      .percent_value
                  }
                  %
                </td>
                <td>
                  {
                    feesStore.c2b[key].customer_withdraw_with_exchange[0]
                      .min_amount
                  }{' '}
                  {feesStore.c2b[key].customer_deposit[0].min_amount_currency}
                </td>
                <td>
                  {feesStore.c2b[key].customer_withdraw[0].percent_value}%
                </td>
                <td>
                  {feesStore.c2b[key].customer_withdraw[0].min_amount}{' '}
                  {feesStore.c2b[key].customer_deposit[0].min_amount_currency}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
})
export default FeesPage
export { C2b, B2b }
