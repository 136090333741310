import { observer } from 'mobx-react'
import type React from 'react'
import { useStore } from '../../models/Store'
import { NavLink, useLocation } from 'react-router-dom'
import './TransactionsPage.scss'
import NavigationWrapper from 'src/components/Navigation/NavigationWrapper/NavigationWrapper'
import NavButton from 'src/components/Navigation/NavButton/NavButton'

const TransactionFilters: React.FC = observer(() => {
  const { transactionStore, initialStore } = useStore()
  const location = useLocation()

  const setTransactionType = (type: string): void => {
    transactionStore.filters.setSearchFields([
      { field: 'transaction_type', value: type }
    ])
  }

  return (
    <NavigationWrapper>
      <NavButton
        title="Show all"
        key={'all'}
        link={`/transactions/all`}
        onClick={() => {
          setTransactionType('')
        }}
      />
      {Object.keys(initialStore.transactions_types).map((key) => {
        return (
          <NavButton
            key={key}
            title={initialStore.transactions_types[key]}
            link={`/transactions/${key}`}
            onClick={() => {
              setTransactionType(key)
            }}
          ></NavButton>
        )
      })}
    </NavigationWrapper>
  )
})

export default TransactionFilters
